<template>
  <div>
    <div class="mass-action-settings">
      <div>
        <cards-stage-select
          v-model="actionData.stage_type"
          placeholder="Выберите этап"
          size="small"
          style="width: 300px"
        ></cards-stage-select>
      </div>
      <div>
        <el-select
          v-model="actionData.date_type"
          style="width: 300px"
          size="small"
          placeholder="Выберите тип даты"
        >
          <el-option label="Дата начала" value="date_start"></el-option>
          <el-option label="Дата окончания" value="date_end"></el-option>
        </el-select>
      </div>
      <div>
        <el-date-picker
          v-model="actionData.date"
          style="width: 300px"
          size="small"
          placeholder="Выберите дату"
          :picker-options="{firstDayOfWeek: 1}"
          type="date"
          value-format="yyyy-MM-dd"
          format="dd.MM.yyyy"
        >
        </el-date-picker>
      </div>


      <el-popconfirm
        confirm-button-text='Да'
        cancel-button-text='нет'
        icon="el-icon-question"
        title="Вы уверены, что хотите изменить шаг?"
        @confirm="doAction"
      >
        <el-button
          style="margin-top: 20px"
          slot="reference"
          size="small"
          type="primary"
          :disabled="actionButtonDisabled"
        >
          Запустить
        </el-button>

      </el-popconfirm>

    </div>

    <mass-action-result
      v-if="result"
      :result="result"
    ></mass-action-result>
  </div>
</template>

<script>
import {ActionTemplateMixin} from "@/components/massActions/actionTemplates/ActionTemplateMixin";
import CardsStageSelect from "@/components/filters/cards/CardsStageSelect.vue";
import MassActionResult from "@/components/massActions/MassActionResult.vue";
import CardsStageNegotiationSelect from "@/components/filters/cards/CardsStageNegotiationSelect.vue";

export default {
  name: "card-set-stage-date-action",
  mixins: [ActionTemplateMixin],
  components: {CardsStageNegotiationSelect, MassActionResult, CardsStageSelect},

  computed: {
    actionButtonDisabled() {
      return !this.actionData.stage_type
        || !this.actionData.date_type
        || !this.actionData.date;
    }
  },
  data() {
    return {
      actionData: {
        stage_type: null,
        date_type: null,
        date: null,
      }
    }
  },
  methods: {},
}
</script>
